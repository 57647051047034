import { List, ListItem, ListItemIcon, Divider, ListItemText, makeStyles, Drawer } from '@material-ui/core';
import { Restore as PreviousVersionIcon, Apps as DashboardIcon, DateRange as DateRangeIcon, Restaurant as NutritionIcon, FitnessCenter as WorkoutIcon, Fastfood as RecipeIcon, PowerSettingsNew as LogoutIcon } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { adminAppPaths, appPaths } from '../AppRoutes';
import { useAuth } from '../authentication/authContext/authContext';

interface SideBarProps {
    open: boolean,
    closeMenu: () => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 280
        }
    },
    nav: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        height: '100%'
    },
    listItem: {
        color: theme.palette.grey[700],
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    listItemText: {
        fontWeight: 500
    },
    activeListItem: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.main,
        borderRight: `4px solid ${theme.palette.primary.main}`,
        '& > div > svg': {
            fill: theme.palette.primary.main
        }
    },
    logout: {
        marginTop: 'auto'
    }
}));

const AdminNavItems: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <Divider />
            <ListItem
                className={classes.listItem}
                component={NavLink}
                to={adminAppPaths.exerciseManager}
                activeClassName={classes.activeListItem}
                exact
            >
                <ListItemIcon>
                    <WorkoutIcon />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}  primary="Exercise Manager" />
            </ListItem>
            <ListItem
                className={classes.listItem}
                component={NavLink}
                to={adminAppPaths.workoutManager}
                activeClassName={classes.activeListItem}
                exact
            >
                <ListItemIcon>
                    <WorkoutIcon />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}  primary="Workout Manager" />
            </ListItem>
            <ListItem
                className={classes.listItem}
                component={NavLink}
                to={adminAppPaths.oldWorkouts}
                activeClassName={classes.activeListItem}
                exact
            >
                <ListItemIcon>
                    <WorkoutIcon />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}  primary="Old Workouts" />
            </ListItem>
        </>
    );
};

const SideBar: React.FC<SideBarProps> = ({
    open,
    closeMenu
}) => {
    const classes = useStyles();
    const auth = useAuth();

    const handleLogout = () => {
        auth.actions?.logout();
    };

    return (
        <Drawer open={open} className={classes.root} onClose={closeMenu} anchor='right'>
            <List component="nav" aria-label="main mailbox folders" className={classes.nav}>
                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to={appPaths.dashboard}
                    activeClassName={classes.activeListItem}
                    exact
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="Dashboard" />
                </ListItem>
                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to={appPaths.schedule}
                    activeClassName={classes.activeListItem}
                    exact
                >
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="My Schedule" />
                </ListItem>
                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to={appPaths.workout}
                    activeClassName={classes.activeListItem}
                    exact
                >
                    <ListItemIcon>
                        <WorkoutIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="Workout" />
                </ListItem>
                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to={appPaths.nutrition}
                    activeClassName={classes.activeListItem}
                    exact
                >
                    <ListItemIcon>
                        <NutritionIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="Nutrition" />
                </ListItem>
                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to={appPaths.recipes}
                    activeClassName={classes.activeListItem}
                    exact
                >
                    <ListItemIcon>
                        <RecipeIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="Recipes" />
                </ListItem>
                {auth.state?.user.type === 'admin' &&
                    <AdminNavItems />
                }
                {auth.state?.user.terms &&
                    <>
                        <hr />
                        <ListItem
                            className={classes.listItem}
                            button
                            component="a"
                            href='https://v1.strongeryou.app'
                        >
                            <ListItemIcon>
                                <PreviousVersionIcon />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText}  primary="Previous Version" />
                        </ListItem>
                    </>
                }
                <ListItem
                    className={`${classes.listItem} ${classes.logout}`}
                    onClick={handleLogout}
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}  primary="Logout" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default SideBar;