import Vimeo from '@u-wave/react-vimeo';
import { makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useHttpRequest } from '../api';
import { getLiveWorkoutById } from '../api/Workout';
import AppLoader from '../components/AppLoader';
import AppButton from '../components/AppButton';
import { addWorkoutLog } from '../api/Log';
import { appPaths } from '../AppRoutes';

interface PageParams {
    id: string
}

const useStyles = makeStyles(theme => ({
    video: {

    },
    title: {

    },
    description: {

    },
    action: {

    }
}));

const LiveWorkoutPage: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams<PageParams>();
    const getWorkouts = useCallback(() => getLiveWorkoutById(id), []);
    const { result: workout, isLoading } = useHttpRequest(getWorkouts);
    const history = useHistory();

    const completeWorkout = async () => {
        if(workout) {
            const result = await addWorkoutLog({
                date: new Date(),
                workoutId: workout._id,
                setLogs: [],
                time: `00:${workout.duration.toString()}:00`
            });
    
            if(result.status === 200) {
                history.push(appPaths.dashboard);
            }
        }
    };

    return (
        <>
            {isLoading && <AppLoader />}
            {workout &&
            <>
                <div className={classes.video}>
                    <Vimeo
                        video={workout.video}
                        autoplay
                        responsive
                        // paused={paused}
                        controls={true}
                        autopause
                        // onReady={() => console.log('ready')}
                        
                    />
                </div>
                <div className={classes.title}>
                    <h2>{workout.title}</h2>
                </div>
                <div className={classes.description} dangerouslySetInnerHTML={{__html: workout.description}}>
                </div>
                <div className={classes.action}>
                    <AppButton color='secondary' fullWidth onClick={completeWorkout}>Complete</AppButton>
                </div>
            </>
            }
        </>
    );
};

export default LiveWorkoutPage;