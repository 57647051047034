import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Equalizer as LevelIcon, DateRange as DurationIcon, FitnessCenter as PerWeekIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useHttpRequest } from '../api';
import { getGuideById } from '../api/Workout';
import AppButton from '../components/AppButton';
import AppLoader from '../components/AppLoader';
import AppModal from '../components/AppModal';
import { addToMySchedule } from '../api/Schedule';
import { successToast } from '../toast';
import { useSchedule } from '../schedule/context/scheduleContext';
import { appPaths } from '../AppRoutes';
import moment from 'moment';
import config from '../config';

interface PageParams {
    id: string
}

const useStyles = makeStyles(theme => ({
    guideImage: {
        position: 'relative',
        width: '100%',
        height: 204,
        borderRadius: theme.shape.borderRadius,
        marginTop: 8,
        marginBottom: 24,
        '& img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: theme.shape.borderRadius
        },
        '& span': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[400],
            transform: 'rotate(4deg)',
            transition: 'all .2s ease-in-out'
        },
    },
    name: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        fontSize: 18,
        fontWeight: theme.typography.fontWeightBold,
        padding: 16,
        color: theme.palette.common.white,
        background: 'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)',
        borderBottomRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
    },
    description: {
        position: 'relative',
        padding: '1rem 0',
        whiteSpace: 'pre-wrap'
    },
    guideTags: {
        '& .MuiChip-root:not(:last-of-type)': {
            marginRight: theme.spacing(2)
        }
    },
    startBtn: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2)
    },
    loader: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const GuideDetailPage: React.FC = () => {
    const classes = useStyles();
    const { guideData, guideSchedule, fetchScheduleData } = useSchedule();
    const history = useHistory();
    const { id } = useParams<PageParams>();
    const getGuideData = useCallback(() => getGuideById(id), []);
    const { result: guide, isLoading } = useHttpRequest(getGuideData);
    const [startDateModal, setStartDateModal] = useState(false);
    const [date, setDate] = useState(new Date());
    const [confirmDialog, setConfirmDialog] = useState(false);
    
    const daysPerWeek = guide && guide.blocks && guide.blocks[0].workouts.filter(workout => workout !== 'restday').length;
    const buttonProps = guideData?._id === guide?._id ? {
        disabled: true,
        label: `Started ${moment(guideSchedule?.startDate).format('Do MMM')}`
    } : {
        disabled: false,
        label: 'Start Guide'
    };

    const handleStartGuide = () => {
        if(guideSchedule) {
            setConfirmDialog(true);
        } else {
            setStartDateModal(true);
        }
    };

    const handleConfirmation = () => {
        setConfirmDialog(false);
        setStartDateModal(true);
    };

    const submitStartGuide = async () => {
        if(guide && guide._id) {
            const result = await addToMySchedule(guide._id, date);

            if(result.status === 200) {
                setStartDateModal(false);
                fetchScheduleData();
                successToast('Guide has been added to your schedule');
                history.push(appPaths.dashboard);
            }
        }
    };

    return (
        <>
            {isLoading ?
                <div className={classes.loader}>
                    <AppLoader />
                </div>
                :
                <>
                    <div className={classes.guideImage}>
                        <span></span>
                        {guide?.image && <img src={`${config.apiBaseUrl}/media/content/${guide.image}`} alt={guide?.name} />}
                        <div className={classes.name}>
                            {guide?.name}
                        </div>
                    </div>
                    <div className={classes.guideTags}>
                        <Chip icon={<LevelIcon />} label={guide?.level} />
                        <Chip icon={<DurationIcon />} label={`${guide?.blocks?.length} Weeks`} />
                        <Chip icon={<PerWeekIcon />} label={`${daysPerWeek} days / week`} />
                    </div>
                    <div className={classes.description}>
                        {guide?.description}
                    </div>
                    <AppButton onClick={handleStartGuide} className={classes.startBtn} fullWidth disabled={buttonProps.disabled}>{buttonProps.label}</AppButton>
                    <AppModal open={startDateModal} handleClose={() => setStartDateModal(false)}>
                        <h2>Select a start date</h2>
                        <DatePicker 
                            autoOk
                            variant='static'
                            minDate={new Date()}
                            value={date}
                            onChange={(date) => setDate(date as Date)} />
                        <div className={classes.buttons}>
                            <AppButton onClick={() => setStartDateModal(false)}>Cancel</AppButton>
                            <AppButton color='secondary' onClick={submitStartGuide}>Start</AppButton>
                        </div>
                    </AppModal>
                    <Dialog 
                        open={confirmDialog}
                        onClose={() => setConfirmDialog(false)}>
                        <DialogTitle>Cancel existing guide?</DialogTitle>
                        <DialogContent>
                            You are already currently following an active guide. Do you want to cancel this existing guide and start <b>{guideData?.name}?</b>
                        </DialogContent>
                        <DialogActions className={classes.buttons}>
                            <AppButton variant='text' onClick={() => setConfirmDialog(false)}>Cancel</AppButton>
                            <AppButton color='secondary' onClick={handleConfirmation}>Confirm</AppButton>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
};

export default GuideDetailPage;