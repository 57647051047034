import { makeStyles, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { useState } from 'react';
import AppModal from '../components/AppModal';
import AppButton from '../components/AppButton';
import AppCard from '../components/AppCard';
import { useSchedule } from './context/scheduleContext';
import { addToMySchedule, cancelGuideSchedule } from '../api/Schedule';
import { successToast } from '../toast';
import config from '../config';

export interface CurrentScheduleProps {
    test?: string
}

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        width: '100%',
        height: 170,
        borderRadius: theme.shape.borderRadius,
        marginTop: 24,
        marginBottom: 24,
        padding: 0,
        overflow: 'visible',
        '& img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: theme.shape.borderRadius
        },
        '& > span': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[400],
            transform: 'rotate(4deg)',
            transition: 'all .2s ease-in-out'
        },
    },
    name: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        fontSize: 18,
        fontWeight: theme.typography.fontWeightBold,
        padding: 16,
        color: theme.palette.common.white,
        background: 'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)',
        borderBottomRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
    },
    currentWeek: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        padding: '2px 8px',
        backgroundColor: '#FFD18D',
        borderRadius: 4
    },
    moreBtn: {
        position: 'absolute',
        top: theme.spacing(0),
        right: theme.spacing(0),
        '& svg': {
            fill: theme.palette.common.white
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const CurrentSchedule: React.FC<CurrentScheduleProps> = ({
    test
}) => {
    const classes = useStyles();
    const { guideData, guideSchedule, currentWeek, fetchScheduleData } = useSchedule();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cancelDialog, setCancelDialog] = useState(false);
    const [startDateModal, setStartDateModal] = useState(false);
    const [date, setDate] = useState(new Date());

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCancel = async () => {
        if(guideSchedule && guideSchedule._id) {
            const result = await cancelGuideSchedule(guideSchedule._id);

            if(result.status === 200) {
                setCancelDialog(false);
                fetchScheduleData();
                successToast('Guide schedule has been cancelled');
            }
        }
    };

    const submitStartGuide = async () => {
        if(guideData && guideData._id) {
            const result = await addToMySchedule(guideData._id, date);

            if(result.status === 200) {
                setStartDateModal(false);
                fetchScheduleData();
                successToast('Guide has been rescheduled');
            }
        }
    };
    
    return (
        <>
            <AppCard className={classes.container}>
                <span></span>
                {guideData?.image && <img src={`${config.apiBaseUrl}/media/content/${guideData.image}`} alt={guideData?.name} />}
                <div className={classes.name}>
                    {guideData?.name}
                </div>
                <div className={classes.currentWeek}>Week {currentWeek}</div>
                <IconButton className={classes.moreBtn} onClick={handleClick}>
                    <MoreIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => setCancelDialog(true)}>Cancel</MenuItem>
                    <MenuItem onClick={() => setStartDateModal(true)}>Restart</MenuItem>
                </Menu>
            </AppCard>
            <Dialog 
                open={cancelDialog}
                onClose={() => setCancelDialog(false)}>
                <DialogTitle>Cancel guide</DialogTitle>
                <DialogContent>
                    Are you sure you want to stop following this workout guide?
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <AppButton variant='text' onClick={() => setCancelDialog(false)}>Cancel</AppButton>
                    <AppButton color='secondary' onClick={handleCancel}>Confirm</AppButton>
                </DialogActions>
            </Dialog>
            <AppModal open={startDateModal} handleClose={() => setStartDateModal(false)}>
                <h2>Select a start date</h2>
                <DatePicker 
                    autoOk
                    variant='static'
                    minDate={new Date()}
                    value={date}
                    onChange={(date) => setDate(date as Date)} />
                <div className={classes.buttons}>
                    <AppButton onClick={() => setStartDateModal(false)}>Cancel</AppButton>
                    <AppButton color='secondary' onClick={submitStartGuide}>Start</AppButton>
                </div>
            </AppModal>
        </>
    );
};

export default CurrentSchedule;