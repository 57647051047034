import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { updateUserDetails } from '../api/Authentication';
import { useAuth } from '../authentication/authContext/authContext';
import AppButton from '../components/AppButton';
import AppCard from '../components/AppCard';
import AppTextField from '../components/AppForm/AppTextField';
import AppPageTitle from '../components/AppPageTitle';
import { errorToast, successToast } from '../toast';

// Refactor all this, it's a mess

const useStyles = makeStyles(theme => ({
    formItem: {
        marginBottom: theme.spacing(2)
    }
}));

const ProfilePage: React.FC = () => {
    const classes = useStyles();
    const { state, actions } = useAuth();
    const [user, setUser] = useState<any | undefined>(state && state.user);

    const handleUserDetailChange = (e: any) => {
        if(e.target.type === 'checkbox') {
            setUser({...user, [e.target.name]: !user[e.target.name]});
        } else {
            setUser({...user, [e.target.name]: e.target.value});
        }   
    };

    const updateUser = async () => {
        const result = await updateUserDetails({...user});

        if(result.status === 200) {
            successToast('Profile details updated');
            actions && actions.refetch();
        } else {
            errorToast('There was a problem updating your details. Please try again.');
        }
    };


    return (
        <>
            <AppPageTitle title='Profile' icon='profile' />
            {state && state.user && user &&
                <AppCard>
                    <div>
                        <h2 className="mb-1">My Details</h2>
                        <div className={classes.formItem}>
                            <AppTextField name='firstname' label='First name' placeholder='First name' value={user.firstname} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className={classes.formItem}>
                            <AppTextField name='lastname' label='Last name' placeholder='Last name' value={user.lastname} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className={classes.formItem}>
                            <AppTextField name='email' label='Email address' placeholder='Email address' value={user.email} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className={classes.formItem}>
                            <label>
                                <input type="checkbox" name="marketing" checked={user.marketing} onChange={(e) => handleUserDetailChange(e)} />
                                <span>I would like to receive emails from Stronger You with information and offers!</span>
                            </label>
                        </div>
                        <AppButton color='secondary' onClick={updateUser}>Update</AppButton>
                    </div>
                </AppCard>
            }
        </>
    );
};

export default ProfilePage;